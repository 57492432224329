.max-line1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .max-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .max-line3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important; /* number of lines to show */
    line-clamp: 3 !important;
    -webkit-box-orient: vertical;
  }
  .max-line4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4 !important; /* number of lines to show */
    line-clamp: 4 !important;
    -webkit-box-orient: vertical;
  }
  .max-line5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5 !important; /* number of lines to show */
    line-clamp: 5 !important;
    -webkit-box-orient: vertical;
  }
  .max-line6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6 !important; /* number of lines to show */
    line-clamp: 6 !important;
    -webkit-box-orient: vertical;
  }
  .max-line7 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7 !important; /* number of lines to show */
    line-clamp: 7 !important;
    -webkit-box-orient: vertical;
  }
  .max-line8 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8 !important; /* number of lines to show */
    line-clamp: 8 !important;
    -webkit-box-orient: vertical;
  }
  .max-line9 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9 !important; /* number of lines to show */
    line-clamp: 9 !important;
    -webkit-box-orient: vertical;
  }
  .max-line10 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10 !important; /* number of lines to show */
    line-clamp: 10 !important;
    -webkit-box-orient: vertical;
  }