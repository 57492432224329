.primary-color {
    color: red($color: #000000) !important;
}

.sub-color {
    color: #838383;
}

.black-color {
    color: #000000;
}

.red-star {
    color: red;
    margin-left: 2px;
}

.white {
    color: white;
}
.blue{
    color: blue;
}
.red{
    color: red;
}

