.h-100pe{
    height:100% !important;
}

.h-100vh{
    height:100vh !important;
}

.h-auto {
    height: auto;
}
.mh-32{
    min-height: 32px;
}
.w-100pe{
    width: 100% !important;
}
.w-100vh{
    width: 100vh !important;
}