/* padding-top */
.pt-0 {
    padding-top: 0px !important;
}

.pt-1  {
    padding-top: 1px !important;
}

.pt-2  {
    padding-top: 2px !important;
}

.pt-3  {
    padding-top: 3px !important;
}

.pt-4  {
    padding-top: 4px !important;
}

.pt-5  {
    padding-top: 5px !important;
}

.pt-6  {
    padding-top: 6px !important;
}

.pt-7  {
    padding-top: 7px !important;
}

.pt-8  {
    padding-top: 8px !important;
}

.pt-9  {
    padding-top: 9px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-11  {
    padding-top: 11px !important;
}

.pt-12  {
    padding-top: 12px !important;
}

.pt-13  {
    padding-top: 13px !important;
}

.pt-14  {
    padding-top: 14px !important;
}

.pt-15  {
    padding-top: 15px !important;
}

.pt-16  {
    padding-top: 16px !important;
}

.pt-17  {
    padding-top: 17px !important;
}

.pt-18  {
    padding-top: 18px !important;
}

.pt-19  {
    padding-top: 19px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-21  {
    padding-top: 21px !important;
}

.pt-22  {
    padding-top: 22px !important;
}

.pt-23  {
    padding-top: 23px !important;
}

.pt-24  {
    padding-top: 24px !important;
}

.pt-25  {
    padding-top: 25px !important;
}

.pt-26  {
    padding-top: 26px !important;
}

.pt-27  {
    padding-top: 27px !important;
}

.pt-28  {
    padding-top: 28px !important;
}

.pt-29  {
    padding-top: 29px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-31  {
    padding-top: 31px !important;
}

.pt-32  {
    padding-top: 32px !important;
}

.pt-33  {
    padding-top: 33px !important;
}

.pt-34  {
    padding-top: 34px !important;
}

.pt-35  {
    padding-top: 35px !important;
}

.pt-36  {
    padding-top: 36px !important;
}

.pt-37  {
    padding-top: 37px !important;
}

.pt-38  {
    padding-top: 38px !important;
}

.pt-39  {
    padding-top: 39px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-41  {
    padding-top: 41px !important;
}

.pt-42  {
    padding-top: 42px !important;
}

.pt-43  {
    padding-top: 43px !important;
}

.pt-44  {
    padding-top: 44px !important;
}

.pt-45  {
    padding-top: 45px !important;
}

.pt-46  {
    padding-top: 46px !important;
}

.pt-47  {
    padding-top: 47px !important;
}

.pt-48  {
    padding-top: 48px !important;
}

.pt-49  {
    padding-top: 49px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-51  {
    padding-top: 51px !important;
}

.pt-52  {
    padding-top: 52px !important;
}

.pt-53  {
    padding-top: 53px !important;
}

.pt-54  {
    padding-top: 54px !important;
}

.pt-55  {
    padding-top: 55px !important;
}

.pt-56  {
    padding-top: 56px !important;
}

.pt-57  {
    padding-top: 57px !important;
}

.pt-58  {
    padding-top: 58px !important;
}

.pt-59  {
    padding-top: 59px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-61  {
    padding-top: 61px !important;
}

.pt-62  {
    padding-top: 62px !important;
}

.pt-63  {
    padding-top: 63px !important;
}

.pt-64  {
    padding-top: 64px !important;
}

.pt-65  {
    padding-top: 65px !important;
}

.pt-66  {
    padding-top: 66px !important;
}

.pt-67  {
    padding-top: 67px !important;
}

.pt-68  {
    padding-top: 68px !important;
}

.pt-69  {
    padding-top: 69px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-71  {
    padding-top: 71px !important;
}

.pt-72  {
    padding-top: 72px !important;
}

.pt-73  {
    padding-top: 73px !important;
}

.pt-74  {
    padding-top: 74px !important;
}

.pt-75  {
    padding-top: 75px !important;
}

.pt-76  {
    padding-top: 76px !important;
}

.pt-77  {
    padding-top: 77px !important;
}

.pt-78  {
    padding-top: 78px !important;
}

.pt-79  {
    padding-top: 79px !important;
}

.pt-80  {
    padding-top: 80px !important;
}
.pt-100  {
    padding-top: 100px !important;
}

.pt-108  {
    padding-top: 108px !important;
}

.pt-120  {
    padding-top: 120px !important;
}