
.rotate0 {
    transform: rotate(0deg);
  }
  .rotate90 {
    transform: rotate(90deg);
  }
  .rotate270 {
    transform: rotate(270deg);
  }
  .rotate360 {
    transform: rotate(360deg);
  }