/* margin-right */
.mr-0 {
    margin-right: 0px !important;
}

.mr-1  {
    margin-right: 1px !important;
}

.mr-2  {
    margin-right: 2px !important;
}

.mr-3  {
    margin-right: 3px !important;
}

.mr-4  {
    margin-right: 4px !important;
}

.mr-5  {
    margin-right: 5px !important;
}

.mr-6  {
    margin-right: 6px !important;
}

.mr-7  {
    margin-right: 7px !important;
}

.mr-8  {
    margin-right: 8px !important;
}

.mr-9  {
    margin-right: 9px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-11  {
    margin-right: 11px !important;
}

.mr-12  {
    margin-right: 12px !important;
}

.mr-13  {
    margin-right: 13px !important;
}

.mr-14  {
    margin-right: 14px !important;
}

.mr-15  {
    margin-right: 15px !important;
}

.mr-16  {
    margin-right: 16px !important;
}

.mr-17  {
    margin-right: 17px !important;
}

.mr-18  {
    margin-right: 18px !important;
}

.mr-19  {
    margin-right: 19px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-21  {
    margin-right: 21px !important;
}

.mr-22  {
    margin-right: 22px !important;
}

.mr-23  {
    margin-right: 23px !important;
}

.mr-24  {
    margin-right: 24px !important;
}

.mr-25  {
    margin-right: 25px !important;
}

.mr-26  {
    margin-right: 26px !important;
}

.mr-27  {
    margin-right: 27px !important;
}

.mr-28  {
    margin-right: 28px !important;
}

.mr-29  {
    margin-right: 29px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-31  {
    margin-right: 31px !important;
}

.mr-32  {
    margin-right: 32px !important;
}

.mr-33  {
    margin-right: 33px !important;
}

.mr-34  {
    margin-right: 34px !important;
}

.mr-35  {
    margin-right: 35px !important;
}

.mr-36  {
    margin-right: 36px !important;
}

.mr-37  {
    margin-right: 37px !important;
}

.mr-38  {
    margin-right: 38px !important;
}

.mr-39  {
    margin-right: 39px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-41  {
    margin-right: 41px !important;
}

.mr-42  {
    margin-right: 42px !important;
}

.mr-43  {
    margin-right: 43px !important;
}

.mr-44  {
    margin-right: 44px !important;
}

.mr-45  {
    margin-right: 45px !important;
}

.mr-46  {
    margin-right: 46px !important;
}

.mr-47  {
    margin-right: 47px !important;
}

.mr-48  {
    margin-right: 48px !important;
}

.mr-49  {
    margin-right: 49px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-51  {
    margin-right: 51px !important;
}

.mr-52  {
    margin-right: 52px !important;
}

.mr-53  {
    margin-right: 53px !important;
}

.mr-54  {
    margin-right: 54px !important;
}

.mr-55  {
    margin-right: 55px !important;
}

.mr-56  {
    margin-right: 56px !important;
}

.mr-57  {
    margin-right: 57px !important;
}

.mr-58  {
    margin-right: 58px !important;
}

.mr-59  {
    margin-right: 59px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.mr-61  {
    margin-right: 61px !important;
}

.mr-62  {
    margin-right: 62px !important;
}

.mr-63  {
    margin-right: 63px !important;
}

.mr-64  {
    margin-right: 64px !important;
}

.mr-65  {
    margin-right: 65px !important;
}

.mr-66  {
    margin-right: 66px !important;
}

.mr-67  {
    margin-right: 67px !important;
}

.mr-68  {
    margin-right: 68px !important;
}

.mr-69  {
    margin-right: 69px !important;
}

.mr-70 {
    margin-right: 70px !important;
}

.mr-71  {
    margin-right: 71px !important;
}

.mr-72  {
    margin-right: 72px !important;
}

.mr-73  {
    margin-right: 73px !important;
}

.mr-74  {
    margin-right: 74px !important;
}

.mr-75  {
    margin-right: 75px !important;
}

.mr-76  {
    margin-right: 76px !important;
}

.mr-77  {
    margin-right: 77px !important;
}

.mr-78  {
    margin-right: 78px !important;
}

.mr-79  {
    margin-right: 79px !important;
}
