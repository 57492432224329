.appChangeRouterProgress {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 2px;
  left: 0;
  z-index: 2000;
  background-color: white;

  .loader {
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 50px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    perspective: 800px;
  }

  .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .inner.one {
    left: 0;
    top: 0;
    animation: rotate-one 1s linear infinite;
    border-bottom: 4px solid red;
  }

  .inner.two {
    right: 0;
    top: 0;
    animation: rotate-two 1s linear infinite;
    border-right: 4px solid Lime;
  }

  .inner.three {
    right: 0;
    bottom: 0;
    animation: rotate-three 1s linear infinite;
    border-top: 4px solid #238cff;
  }

  @keyframes rotate-one {
    0% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-two {
    0% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-three {
    0% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
  }
}

.overlay {
  position: unset !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    top: unset;
    left: unset;
    height: 50px;
    width: 50px;
  }
}

.loadingTable {
  background: none;
  position: unset;
  .loader {
    top: 50%;
  }
}

.sizeSmall {
  .loader {
    width: 40px;
    height: 40px;
  }
}
