.disable-btn{
    border-color:#ccc!important;
    color:#ccc!important;
    :hover{
        border-color:#ccc!important;
        color:#ccc!important;

    }
    div{
        color:#ccc!important;
        div{
            color:#ccc!important;
            span{
            color:#ccc!important;

            }
        }}
}